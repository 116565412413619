<template>
<Header/>
<router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");

body{
  margin: 0;
  padding: 0;
  background-color:#333333;
  background-image: url("assets/image/bg/diagmonds.png");
  color:#cecece;
  box-sizing: border-box;
}
*{
  box-sizing: border-box;
  font-family: "PT Sans", sans-serif;
}
h2{
  font-family: 'PT Sans', sans-serif;
  text-align: center;
  font-size: 48px;
  margin: .3em 0;
  text-shadow: 0px 0px 3px #cecece;
}
</style>

<script>
// @ is an alias to /src

import Header from '@/components/Header/index.vue'

export default {
  name: 'App',
  components: {
    Header
  }
}
</script>

<template>
  <div class="top">
    <h2>Parcours</h2>
  </div>
  <div class="contentGame" v-if="!$isMobile()  && play">
    <GameCanvas @getCoords="getCoords($event)"/>
     <div class="notification">
       <p class="date">{{actualDate}}</p>
       <p v-if="date[0]">Avance dans le jeu afin de connaître mon parcours !</p>
       <p v-if="date[1]">6ème au Collège les Frères le Nain</p>
       <p v-if="date[2]">5ème au Collège les Frères le Nain</p>
       <p v-if="date[3]">4ème au Collège les Frères le Nain</p>
       <p v-if="date[4]">3ème au Collège les Frères le Nain, Brevet avec mention</p>
       <p v-if="date[5]">Seconde au Lycée Pierre Méchain</p>
       <p v-if="date[6]">1ère Scientifique au Lycée Pierre Méchain</p>
       <p v-if="date[7]">Terminale Scientifique au Lycée Pierre Méchain, Bac avec mention</p>
       <p v-if="date[8]">1ère année de licence à l'Université de Reims Champagne-Ardenne et entré dans la réserve de l'Armée de Terre</p>
       <p v-if="date[9]">2nd année de licence à l'Université de Reims Champagne-Ardenne, et chef d'équipe à l'Armée de Terre</p>
     </div>
     <div class="move" v-if="date[0]">
       <div class="key">
         <div style="grid-area: Z">Z</div>
         <div style="grid-area: Q">Q</div>
         <div style="grid-area: S">S</div>
         <div style="grid-area: D">D</div>
       </div>
       <div class="arrow">
         <div style="grid-area: UP">↑</div>
         <div style="grid-area: LEFT">←</div>
         <div style="grid-area: DOWN">↓</div>
         <div style="grid-area: RIGHT">	→</div>
       </div>
     </div>
  </div>
  <div class="contentParcours" v-if="$isMobile() || !play">
    <div>
      <div class="left">De 2019 à Aujourd'hui</div>
      <div class="point"></div>
      <div class="right">
        <h3 class="title">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-briefcase-fill" viewBox="0 0 16 16"><path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/><path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/></svg>
          Réserviste à l'Armée de Terre
        </h3>
        <div class="lieu">501e régiment de chars de combat, Mourmelon-le-Grand (51)</div>
        <div class="description">
          <ul>
            <li>Gestion de l'armurerie</li>
            <li>Chef d'équipe</li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <div class="left">De 2019 à 2021</div>
      <div class="point"></div> 
      <div class="right">
        <h3 class="title">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard-fill" viewBox="0 0 16 16"><path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5Z"/><path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Z"/></svg>
           Cursus universitaire, Informatique (niv. II)
        </h3>
        <div class="lieu">Université de Reims Champagne-Ardenne, Reims (51)</div>
        <div class="description">
          <ul>
            <li>Première et deuxième année</li>
            <li>Vu les bases de différents languages de programmation et réseaux</li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <div class="left">De 2017 à 2019</div>
      <div class="point"></div>
      <div class="right">
        <h3 class="title">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard-fill" viewBox="0 0 16 16"><path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5Z"/><path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Z"/></svg>
           Diplome national du baccalauréat, Scientifique (niv. IV)
        </h3>
        <div class="lieu">Lycée Pierre Méchain, Laon (02)</div>
        <div class="description">
          <ul>
            <li>Mention assez bien</li>
            <li>Option Science de l'ingénieur (SI)</li>
            <li>Spécialité informatique (ISN)</li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <div class="left">De 2013 à 2016</div>
      <div class="point"></div>
      <div class="right">
        <h3 class="title">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard-fill" viewBox="0 0 16 16"><path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5Z"/><path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Z"/></svg>
           Diplome national du brevet, Général (niv. V)
        </h3>
        <div class="lieu">Collège les Frères le Nain, Laon (02)</div>
        <div class="description">
          <ul>
            <li>Mention bien</li>
          </ul>
        </div>
      </div>
      
    </div>
    <button class="btnRight" v-if="!$isMobile() && !play" v-on:click="playGame()">{{msg}}</button>
  </div>
  <button v-if="!$isMobile() && play" v-on:click="playGame()">{{msg}}</button>
</template>
<script>  

import GameCanvas from '@/components/GameCanvas/index.vue'

export default {
  name: 'Parcours',
    components: {
    GameCanvas
  },
  data(){
    return {
      date: [0,0,0,0,0,0,0,0,0],
      realDate : ["", 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
      actualDate : "",
      coords:0,
      play:true,
      msg: "Accéder sans jouer",
    }
  },
  methods: {
    getCoords(coord) {
      this.coords = coord;
      this.getDate()
    },
    getDate(){
      this.date = [0,0,0,0,0,0,0,0,0];
      this.date[Math.round(this.coords/170)] = 1;
      this.actualDate = this.realDate[Math.round(this.coords/170)]
    },
    playGame(){
      this.play = !this.play
      if(this.play){
        this.msg = "Accéder sans jouer"
      }else{
        this.msg = "Accéder au jeu"
      }
    }
  },
    created() {
      this.date = [0,0,0,0,0,0,0,0,0];
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btnRight{
  position: fixed;
  margin:0;
  top: calc(50% + 35px);
  right:5%;
  padding: 1%;
}
.contentParcours{
  display:grid;
  grid-template-columns: repeat(1, 90%);
  justify-items: center;
  grid-row-gap: 20px;
}
.contentParcours>div{
  width:80%;
  display: grid;
  grid-template-columns: 15% 20px 65%;
  grid-column-gap: 3%;
  justify-content: space-between;
  align-items: center;
  
}
.point{
  position: relative;
  width: 20px;
  height: 100%;
}
.point::before{
    position: absolute;
    top:50%;
    left: 50%;
    content: '';
    border: 6px solid #cecece;
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    width: 8px;
    line-height: 24px;
    background: #cecece;
}
.point::after{
    position: absolute;
    left: calc(50% + 8px);
    content: "";
    height: calc(100% + 20px);
    width: 0;
    border-left: 4px solid #cecece;
}
.right{
  border: #333333 1px solid;
  width: 100%;
  background-color: #cecece;
  color: #333333;
  border-radius: 25px;
  box-shadow: 5px 5px 5px 0px #333333, inset 4px 4px 15px 0px #333333, 5px 5px 15px 5px rgba(0,0,0,0);
  padding: 20px;
}
h3{
  margin:0;
}
button{
  display: block;
  margin: 10px auto 0px auto;
  background-color: #333333;
  color: #cecece;
  font-size: 18px;
}
.notification{
  width: calc(80% + 2px);
  height: 50px;
  background-color: black;
  opacity: 0.4;
  margin: 0 auto;
  display:table;
  position:relative;
  font-size: 25px;
}
.move{
  width: calc(80% + 2px);
  height: 110px;
  background-color: black;
  opacity: 0.4;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.key{
  display:grid;
  grid-template-areas: ". Z ." "Q S D";
  justify-content: center;
  grid-gap: 5px;
  height:auto;
  width: 25%;
}
.arrow{
  display:grid;
  grid-template-areas: ". UP ." "LEFT DOWN RIGHT";
  justify-content: center;
  grid-gap: 5px;
  height:auto;
  width: 25%;
}
.move>div>div{
  width: 38px;
  text-align: center;
  padding: 5px;
  border: 2px solid white;
}
p{
  margin:0;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  color:white;
  padding: 0 20%;
}
.date{
  padding: 0;
  position: absolute; 
  top: calc(50% - 12px);
  margin-left: 10px;
}
@media (max-width: 768px) {
  .left{
    display: none;
  }
  .point{
    display: none;
  }
  .contentParcours>div{
    grid-template-columns: 100%;
    justify-content: stretch;
  }
  .contentParcours{
    grid-template-columns: none;
  }
}
</style>

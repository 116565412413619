<template>
  <div class="top">
    <h2>Contact</h2>
  </div>
  <div class="main">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d58508.90493921124!2d3.6097598391840684!3d49.6024125480373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2sfr!4v1641378683085!5m2!1sfr!2sfr?z=10" width="410" height="410" allowfullscreen="" loading="lazy"></iframe>
    <form action="https://api.guillaume-delfly.fr/index.php" method="post">
      <div>
          <i class="bi bi-person-fill"></i>
          <input class="form-control" type="text" id="name" name="name" placeholder="Nom et prénom" required>
      </div>
      <div>
          <i class="bi bi-envelope-fill"></i>
          <input class="form-control" type="email" id="mail" name="email" placeholder="E-mail" required>
      </div>
      <div>
        <i class="bi bi-telephone-fill"></i>    
        <input class="form-control" type="tel" id="phone" name="phone" placeholder="Numéro de téléphone">
      </div>
      <div>
        <i class="bi bi-chat-text-fill"></i>
        <textarea class="form-control"  id="msg" name="message" placeholder="Votre message"></textarea>
      </div>
      <div class="buttdiv">
        <button class="button" type="submit"><i class="bi bi-send"></i>&nbsp;Envoyez</button>
      </div>
    </form>
    <div class="social">
      <h4><i class="bi bi-geo-alt"></i> Adresse :</h4>
      <p>12 place des aulnes, 02000 Aulnois-Sous-Laon</p>
      <hr>
      <h4><i class="bi bi-telephone-fill"></i> Téléphone : </h4>
      <p><a href="callto:+33767362244">07 67 36 22 44 </a></p>
      <hr>
      <h4><i class="bi bi-envelope-fill"></i> E-Mail : </h4>
      <p><a href="mailto:delfly.guillaume@gmail.com" >delfly.guillaume@gmail.com</a></p>
      <hr>
      <h4><i class="bi bi-linkedin"></i> LinkedIn : </h4>
      <p><a href="https://www.linkedin.com/in/guillaume-delfly-60729517a/" target="_blank">Guillaume Delfly</a></p>
      <hr>
      <h4><i class="bi bi-github"></i> GitHub : </h4>
      <p><a href="https://github.com/RedTenZ" target="_blank">RedTenZ</a></p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Contact'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a:link 
{
  color: #cecece;
  text-decoration:none; 
} 
a:visited{
  color: #cecece;
}
a:hover{
  color: white;
}
hr{
  width: 75%;
  border-color: #cecece;
  border-top: 0;
}
h4{
  margin:0;
}
.social{
  padding:10px;
  width: 100%;
  height: 100%;
  text-align: center;
  border: #cecece83 3px solid;
  box-sizing: border-box;
}
iframe{
  box-sizing: border-box;
  border: #cecece83 3px solid;
  width: 100%;
  height: 100%;
}
*{
  box-sizing: border-box;
  font-family: "PT Sans", sans-serif;
}
.buttdiv{
  position: absolute;
  text-align: center;
  height: 50%;
  top:50%;
  background-color: #cecece;
}
.button{
  position: absolute;
  background-color: #cecece;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 20px; 
  left: calc(50% - 55px);
  bottom: 0;
  border: #33333325 1px solid;
}
input{
  padding: 0px;
}
.main{
  margin:0 17%;
  margin-top: 3%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
}
form{
  display: inline-block;
  position: relative;
  height: 100%;
}
.form-control{
  height: 40px;
  font-size: 20px;
  padding-left: 30px;
  width: 100%;
  margin-bottom:2%;
  background-color: #cecece;
  border: none;
}
form>div{
  position: relative;
  width: 100%;
}
form>div>i{
  position: absolute;
  color: #050202;
  margin: 5px;
  margin-top: 7px;
}
.bi-chat-text-fill{
  margin-top: 0;
}
textarea
{
 resize : none;
 height: 220px !important;
 padding: 0px;
}
h2{
  font-family: 'PT Sans', sans-serif;
  text-align: center;
  font-size: 48px;
  margin: .3em 0;
}

@media (max-width: 1000px) {
  .main{
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    row-gap: 20px;
  }
  iframe{
    margin-bottom: 5%;
  }

  .social{
    margin-left: 0;
  }

}
</style>

export default {
    name: 'header',
    components: {},
    props: [],
    data() {
        return {
            show: false,
            mention: true,
            cgu: false,
        }
    },
    computed: {

    },
    mounted() {
        let span = document.getElementsByClassName("close")[0];
        let modal = document.getElementById("modal");
        let btn = document.getElementById("modalButton");
        let footer = document.getElementsByClassName('footer')[0];
        btn.onclick = function() {
            modal.style.display = "block";
        }
        span.onclick = function() {
            modal.style.display = "none";
        }

        window.onclick = function(event) {
            if (event.target == modal) {
                modal.style.display = "none";
            }
        }

        window.onscroll = function() {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                footer.style.display = "flex";
            } else {
                footer.style.display = "none";
            }
        };

        document.onreadystatechange = () => {
            if (document.readyState == "complete") {
                if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                    footer.style.display = "flex";
                }
            }
        }


    },
    methods: {
        changeActive(toChange) {
            let mentionButton = document.getElementsByClassName("mention")[0];
            let cguButton = document.getElementsByClassName("cgu")[0];
            if (toChange == "mention") {
                this.mention = true;
                mentionButton.classList.add('active');
                this.cgu = false;
                cguButton.classList.remove('active')
            } else {
                this.cgu = true;
                cguButton.classList.add('active');
                this.mention = false;
                mentionButton.classList.remove('active')
            }
        }
    }
}
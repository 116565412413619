<template>
  <div class="top">
    <h2>Projets</h2>
  </div>
</template>
<script>
export default {
  name: 'Projets'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex-row>div{
  width: 30%;
}
.flex-row{
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
}
img{
  position: absolute;
  width: auto;
  margin-top: 4%;
  margin-left: 50%;
  transform: translate(-50%);
}
p{
  padding: 20px;
  margin-top:0px;
  font-family: 'PT Sans', sans-serif;
  font-size: 24px;
}
h3 {
  text-align: center;
  font-size: 1.2em;
  width: 33%;
  margin-right: 50%;
  margin-bottom: 0;
  transform: translateX(100%);
}
</style>

<template>
  <div class="topdiv">
    <h2>Compétences</h2>
    <div v-if="!$isMobile()" class="center">Survolez l'îcone pour plus d'informations.</div>
    <div class="flex-row">
      
      <div>
        <h3>FrontEnd</h3>
        <ul>
          <li v-on:mouseover="wikipedia('Hypertext_Markup_Language')">
            <img
              src="../assets/image/logo/Hypertext_Markup_Language.png"
              alt="HTML"
            /><span>HTML</span>
          </li>
          <li v-on:mouseover="wikipedia('Feuilles_de_style_en_cascade')">
            <img
              src="../assets/image/logo/Feuilles_de_style_en_cascade.png"
              alt="CSS"
            /><span>CSS</span>
          </li>
          <li v-on:mouseover="wikipedia('Sass_(langage)')">
            <img
              src="../assets/image/logo/Sass_(langage).png"
              alt="Sass"
            /><span>Sass</span>
          </li>
          <li v-on:mouseover="wikipedia('Bootstrap_(framework)')">
            <img
              src="../assets/image/logo/Bootstrap_(framework).png"
              alt="Bootstrap"
            /><span>Bootstrap</span>
          </li>
          <li v-on:mouseover="wikipedia('JavaScript')">
            <img
              src="../assets/image/logo/JavaScript.png"
              alt="Javascript"
            /><span>Javascript</span>
          </li>
          <li v-on:mouseover="wikipedia('AngularJS')">
            <img
              src="../assets/image/logo/angularjs.png"
              alt="AngularJS"
            /><span>AngularJS</span>
          </li>
          <li v-on:mouseover="wikipedia('Vue.js')">
            <img src="../assets/image/logo/Vue.js.png" alt="VueJS" /><span
              >VueJS</span
            >
          </li>
          <li v-on:mouseover="wikipedia('JQuery')">
            <img src="../assets/image/logo/jquery.png" alt="Jquery" /><span
              >Jquery</span
            >
          </li>
        </ul>
      </div>

      <div>
        <h3>BackEnd</h3>
        <ul>
          <li v-on:mouseover="wikipedia('PHP')">
            <img src="../assets/image/logo/php.png" alt="PHP" /><span>PHP</span>
          </li>
          <li v-on:mouseover="wikipedia('Laravel')">
            <img src="../assets/image/logo/laravel.png" alt="Laravel" /><span
              >Laravel</span
            >
          </li>
          <li v-on:mouseover="wikipedia('Symfony')">
            <img src="../assets/image/logo/symfony.png" alt="Symfony" /><span
              >Symfony</span
            >
          </li>
          <li v-on:mouseover="wikipedia('Node.js')">
            <img src="../assets/image/logo/Node.js.png" alt="nodeJS" /><span
              >NodeJS</span
            >
          </li>
          <li v-on:mouseover="wikipedia('Express.js')">
            <img
              src="../assets/image/logo/Express.js.png"
              alt="expressJS"
            /><span>ExpressJS</span>
          </li>
          <li v-on:mouseover="wikipedia('Structured_Query_Language')">
            <img
              src="../assets/image/logo/Structured_Query_Language.png"
              alt="sql"
            /><span>SQL</span>
          </li>
        </ul>
      </div>

      <div>
        <h3>Autres</h3>
        <ul>
          <li v-on:mouseover="wikipedia('GitHub')">
            <img src="../assets/image/logo/github.png" alt="github" /><span
              >GitHub</span
            >
          </li>
          <li v-on:mouseover="wikipedia('FileZilla')">
            <img
              src="../assets/image/logo/filezilla.png"
              alt="filezilla"
            /><span>FileZilla</span>
          </li>
          <li v-on:mouseover="wikipedia('JavaScript_Object_Notation')">
            <img
              src="../assets/image/logo/JavaScript_Object_Notation.png"
              alt="json"
            /><span>JSON</span>
          </li>
          <li v-on:mouseover="wikipedia('Java_(langage)')">
            <img
              src="../assets/image/logo/Java_(langage).png"
              alt="java"
            /><span>Java</span>
          </li>
          <li v-on:mouseover="wikipedia('Python_(langage)')">
            <img
              src="../assets/image/logo/Python_(langage).png"
              alt="python"
            /><span>Python</span>
          </li>
          <li v-on:mouseover="wikipedia('Adobe_Photoshop')">
            <img
              src="../assets/image/logo/Adobe_Photoshop.png"
              alt="photoshop"
            /><span>Photoshop</span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="show && !$isMobile()" ref="desc">
      <div class="skills">
        <div class="progressbar-wrapper">
          <div class="progressbar">
            <div class="plane front">
              <div class="filler" :style="{ height: progressheight + '%' }"></div>
            </div>
            <div class="plane back">
              <div class="filler" :style="{ height: progressheight + '%' }"></div>
            </div>
            <div class="plane top"></div>
            <div class="plane bottom"></div>
            <div class="plane left">
              <div class="filler" :style="{ height: progressheight + '%'}"></div>
            </div>
            <div class="plane right">
              <div class="filler" :style="{ height: progressheight + '%'}"></div>
            </div>
          </div>
        </div>
      </div>
      <transition name="slide-fade" mode="out-in">
        <div class="desc" :key="info">
          <h4>D'après wikipédia :</h4>
          {{ info }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Competences",
  data() {
    return {
      img: null,
      info: null,
      actual: null,
      show: false,
      progress: {
        Hypertext_Markup_Language: 95,
        Feuilles_de_style_en_cascade: 95,
        Sass_langage: 85,
        Bootstrap_framework: 100,
        JavaScript: 85,
        AngularJS: 70,
        Vuejs: 80,
        JQuery: 70,

        PHP: 90,
        Laravel: 40,
        Symfony: 90,
        Nodejs: 80,
        Expressjs: 90,
        Structured_Query_Language: 60,

        GitHub: 80,
        FileZilla: 100,
        JavaScript_Object_Notation: 100,
        Java_langage: 70,
        Python_langage: 95,
        Adobe_Photoshop: 60,
      },
      progressheight: "10",
    };
  },
  methods: {
    wikipedia: function (event) {
      this.show = true;
      axios
        .get(
          "https://fr.wikipedia.org/w/api.php?format=json&action=query&prop=extracts&exintro&explaintext&redirects=1&titles=" +
            event +
            "&origin=*&callback="
        )
        .then((response) => {
          this.info = response.data.slice(5, -1);
          this.info = JSON.parse(this.info);
          this.info = this.info["query"]["pages"];
          for (var key in this.info) {
            this.info = this.info[key]["extract"];
          }
        });
      event = event
        .replace(/[{()}]/g, "")
        .split(".")
        .join("");
      
      if(event!=this.actual){
        //Animation
        clearInterval(id);
        var id = null;
        this.progressheight = 0
        id = setInterval(function(){
          if(this.progressheight<this.progress[event]){
            this.progressheight = this.progressheight + 1
          }else{
            clearInterval(id)
          }
        }.bind(this), 10);
        this.actual=event
        }



    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.center{
  text-align: center;
  font-style: italic;
}

@keyframes infoIn {
  0% {
    opacity: 0;
    transform: translateX(-250px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes infoOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-250px);
  }
}

.slide-fade-enter-active {
  animation: infoIn 1s ease 0s 1 normal forwards;
}
.slide-fade-leave-active {
  animation: infoOut 1s ease 0s 1 normal forwards;
}

/* Progressbar */
.progressbar-wrapper {
  position: absolute;
  width: 60px; 
  height: 300px;
  margin-top: -5%;
  margin-left: 78%;
}
.progressbar {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform: rotateX(-20deg) rotateY(-30deg);
}
.plane {
  width: 100%;
  height: 100%;
  background-color: rgba(206, 206, 206, 0.4);
  top: 0;
  left: 0;
  position: absolute;
}
.top {
  height: 60px;
  transform: translate(0, -100%) rotateX(90deg);
  transform-origin: bottom;
}
.bottom {
  height: 60px;
  transform: translate(0, 240px) rotateX(90deg);
  transform-origin: bottom;
  background-color: rgba(227, 235, 2, 0.6);
}
.back {
  transform: translateZ(-60px);
}
.left {
  transform: rotateY(90deg);
  transform-origin: left;
}
.right {
  transform: rotateY(270deg);
  transform-origin: right;
}
.filler {
  transform: translateY(300px) rotateZ(180deg);
  transform-origin: top;
  height: 50%;
  background-color: rgba(227, 235, 2, 0.6);
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
}
.desc {
  display: block;
  margin: 5%;
  margin-left: 10%;
  margin-bottom: 0;
  text-align: justify;
  width: 60%;
}
* {
  font-family: "PT Sans", sans-serif;
}
ul {
  padding: 0px;
  list-style-type: none;
  width: 60%;
  margin-left: 50%;
  transform: translate(-50%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
li {
  cursor: pointer;
  text-align: center;
}
li > span {
  margin-top: 10%;
  display: block;
}
img {
  height: 40px;
  width: auto;
}

.flex-row > div {
  width: 30%;
}
.flex-row {
  margin-top: 1%;
  display: flex;
  justify-content: center;
}
p {
  padding: 20px;
  margin-top: 0px;
  font-family: "PT Sans", sans-serif;
  font-size: 24px;
}
h3 {
  text-align: center;
  font-size: 1.2em;
  width: 100%;
  margin-bottom: 0;
}
@media (max-width: 1000px) {
  img {
    height: 30px;
  }
  span {
    font-size: 12px;
  }
}

@media (max-width: 700px) {
  .flex-row {
    flex-direction: column;
  }
  .flex-row > div {
    width: 100%;
  }
  .progressbar-wrapper {
    margin-top: 10%;
  }
}

@media (max-width: 450px) {
  .progressbar-wrapper {
    width: 30px;
    height: 150px;
  }
  .top {
    height: 30px;
  }
  .bottom {
    height: 30px;
    transform: translate(0, 120px) rotateX(90deg);
  }
  .back {
    transform: translateZ(-30px);
  }
  .filler {
    transform: translateY(150px) rotateZ(180deg);
  }
  .desc {
    font-size: 12px;
  }
}
</style>

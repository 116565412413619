<template>

  <div class="main-card">
    <h3 class="title">Développeur Web</h3>
    <img src="../assets/image/other/maphoto.png">
    <h3 class="name">Guillaume DELFLY</h3>
    <div class="desc"><h4>Développeur web jeune et innovant, passionné par le développement et autodidacte sur le sujet depuis le collège</h4></div>
    <div style="grid-area:side1; background-color: rgba(51,51,51,.9);">
      <p>Le web est désormais un service indispensable à notre quotidien. Les sites web doivent être fluides, simples et être en mesure de protéger les différentes informations qu'ils peuvent utiliser.</p>
      <p>La technologie web avance et évolue chaque jour c'est pourquoi je m'investis dans le domaine et cherche à progresser et apprendre en permanence.</p>
      <p>Ma fonction première serait donc de proposer le développement des sites internets désormais indispensable au quotidien.</p>
    </div>
    
    <div style="grid-area:side2; background-color: rgba(51,51,51,.9);">
      <h3>JE SUIS : </h3>
      <ul>
        <li>âgé de 21 ans.</li>
        <li>rigoureux et à l'écoute</li>
        <li>autonome et persévérant.</li>
        <li>dôté du permis et d'un véhicule</li>
        <li>capable de parler Anglais sans difficulté (C1)</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Propos'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.flex-row{
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
}
</style>
<style scoped>
li{
    font-family: 'PT Sans', sans-serif;
  font-size: large;
}
ul{
  padding:0;
  text-align: center;
  list-style-type: none;
}
.title{
  padding: 24px 0px;
  margin: 0;
  /* background-color: rgba(51,51,51,.9); */
  background-color: rgba(120,120,120,.5);
  grid-area: title;
  
}
.name{
  grid-area: name;
  /* background-color: rgba(51,51,51,.9); */
  background-color: rgba(120,120,120,.5);
}

.desc{
  grid-area: desc;
  /* background-color: rgba(51,51,51,.9); */
  background-color: rgba(120,120,120,.5);
  font-size: larger;
}
.main-card{
  margin-top:2%;
  margin-left: auto;
  margin-right: auto;
  width: 64%;
  display: grid;
  grid-template:
  "title title"
  "img side1"
  "img side1"
  "img side1"
  "name side2"
  "desc side2"
  "desc side2";
  background-image: url("../assets/image/bg/diagmonds.png");
  position:relative;
  margin-bottom: 70px;
}

img{
  grid-area: img;
  margin: 0 auto;
  padding: 20px;
}
h3{
  text-align: center;
  font-size: x-large;
  margin: 0;
  padding: 24px 0;
}
h4 {
  text-align: center;
  width: 60%;
  margin-left: 20%;
}
p{
  padding: 5px 20px;
  font-family: 'PT Sans', sans-serif;
  font-size: large;
}

@media (max-width: 1200px) {
  .main-card{
    margin-left: auto;
    margin-right: auto;
    width: 84%;
  }
}

@media (max-width: 950px) {
  img{
    width:100%;
  }
}

@media (max-width: 620px) {

  .main-card{
  grid-template:
  "title"
  "img"
  "name"
  "desc"
  "side1"
  "side2";
  }
}

</style>

import { createRouter, createWebHashHistory } from 'vue-router'
import Propos from '../views/Propos.vue'
import Projets from '../views/Projets.vue'
import Loisirs from '../views/Loisirs.vue'
import Contact from '../views/Contact.vue'
import Competences from '../views/Competences.vue'
import Parcours from '../views/Parcours.vue'

const routes = [{
        path: '/propos',
        component: Propos
    },
    {
        path: '/projets',
        component: Projets
    },
    {
        path: '/loisirs',
        component: Loisirs
    },
    {
        path: '/contact',
        component: Contact
    },
    {
        path: '/competences',
        component: Competences
    },
    {
        path: '/parcours',
        component: Parcours
    },
    {
        path: '/',
        component: Propos
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
import rest from "@/assets/image/animations/stickmenRest.png";
import cube from "@/assets/image/gameAsset/cube1.png";
import marcheRight1 from "@/assets/image/animations/marcheRight1.png";
import marcheRight2 from "@/assets/image/animations/marcheRight2.png"
import marcheRight3 from "@/assets/image/animations/marcheRight3.png"
import marcheRight4 from "@/assets/image/animations/marcheRight4.png"
import marcheRight5 from "@/assets/image/animations/marcheRight5.png"
import marcheRight6 from "@/assets/image/animations/marcheRight6.png"
import marcheLeft1 from "@/assets/image/animations/marcheLeft1.png"
import marcheLeft2 from "@/assets/image/animations/marcheLeft2.png"
import marcheLeft3 from "@/assets/image/animations/marcheLeft3.png"
import marcheLeft4 from "@/assets/image/animations/marcheLeft4.png"
import marcheLeft5 from "@/assets/image/animations/marcheLeft5.png"
import marcheLeft6 from "@/assets/image/animations/marcheLeft6.png"
import jumpRight1 from "@/assets/image/animations/jumpRight1.png"
import jumpRight2 from "@/assets/image/animations/jumpRight2.png"
import jumpRight3 from "@/assets/image/animations/jumpRight3.png"
import jumpRight4 from "@/assets/image/animations/jumpRight4.png"
import jumpRight5 from "@/assets/image/animations/jumpRight5.png"
import jumpRight6 from "@/assets/image/animations/jumpRight6.png"
import jumpRight7 from "@/assets/image/animations/jumpRight7.png"
import jumpRight8 from "@/assets/image/animations/jumpRight8.png"
import jumpLeft1 from "@/assets/image/animations/jumpLeft1.png"
import jumpLeft2 from "@/assets/image/animations/jumpLeft2.png"
import jumpLeft3 from "@/assets/image/animations/jumpLeft3.png"
import jumpLeft4 from "@/assets/image/animations/jumpLeft4.png"
import jumpLeft5 from "@/assets/image/animations/jumpLeft5.png"
import jumpLeft6 from "@/assets/image/animations/jumpLeft6.png"
import jumpLeft7 from "@/assets/image/animations/jumpLeft7.png"
import jumpLeft8 from "@/assets/image/animations/jumpLeft8.png"

export default {
    name: 'game-canvas',
    data() {
        return {
            coord: 0,
        }
    },
    mounted() {
        var canvas = document.getElementById("game-canvas");
        var ctx = canvas.getContext("2d");
        var playerHeight = 87;
        var playerWidth = 67;
        var playerX = 0;
        var playerY = (canvas.height - playerHeight);
        var rightPressed = false
        var leftPressed = false;
        var upPressed = false;
        var obstacle = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 1, 1, 0, 1, 1, 1, 0, 0, 1, 0, 1, 0, 0, 0, 1, 0, 0],
            [0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 1, 1, 0],
            [0, 1, 1, 1, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 1],
        ]
        var tabImgRight = [marcheRight1, marcheRight2, marcheRight3, marcheRight4, marcheRight5, marcheRight6];
        var tabImgLeft = [marcheLeft1, marcheLeft2, marcheLeft3, marcheLeft4, marcheLeft5, marcheLeft6];
        var tabJumpRight = [jumpRight1, jumpRight2, jumpRight3, jumpRight4, jumpRight5, jumpRight6, jumpRight7, jumpRight8];
        var tabJumpLeft = [jumpLeft1, jumpLeft2, jumpLeft3, jumpLeft4, jumpLeft5, jumpLeft6, jumpLeft7, jumpLeft8];
        var destinationHeight;
        var obs = new Image;
        obs.src = cube;
        var img = new Image;
        var count = 0;
        var timer = 0
        var lastKey;
        img.src = tabImgRight[0]


        var onGround = true;

        document.addEventListener("keydown", keyDownHandler, false);
        document.addEventListener("keyup", keyUpHandler, false);

        function keyDownHandler(e) {
            if (e.keyCode == 39 || e.keyCode == 68) {
                rightPressed = true;
                lastKey = "right"
            } else if (e.keyCode == 37 || e.keyCode == 81) {
                leftPressed = true;
                lastKey = "left"
            } else if (e.keyCode == 38 || e.keyCode == 90 || e.keyCode == 32) {
                if (onGround && !upPressed) {
                    upPressed = true;
                    count = 0;
                    timer = 0;
                    destinationHeight = playerY - playerHeight;
                    if (destinationHeight <= 0) {
                        destinationHeight = 2
                    }
                }
            }

        }

        function keyUpHandler(e) {
            if (e.keyCode == 39 || e.keyCode == 68) {
                rightPressed = false;
                if (leftPressed) {
                    lastKey = "left"
                }
            } else if (e.keyCode == 37 || e.keyCode == 81) {
                leftPressed = false;
                if (rightPressed) {
                    lastKey = "right"
                }
            }
        }


        function detectColission(playernextX, playernextY) {
            let myBool = true;
            for (var y in obstacle) {
                for (var x in obstacle[y]) {
                    if (obstacle[y][x]) {
                        if (playernextX + playerWidth / 3 > x * 80 && playernextX + playerWidth / 3 < x * 80 + 80) {
                            if (playernextY >= y * 80 && playernextY < y * 80 + 80) {
                                myBool = false
                            }
                        }
                        if (playernextX + playerWidth - playerWidth / 3 > x * 80 && playernextX - playerWidth / 3 + playerWidth < x * 80 + 80) {
                            if (playernextY >= y * 80 && playernextY < y * 80 + 80) {
                                myBool = false
                            }
                        }
                        if (playernextX + playerWidth / 3 > x * 80 && playernextX + playerWidth / 3 < x * 80 + 80) {
                            if (playernextY + playerHeight - 1 >= y * 80 && playernextY + playerHeight <= y * 80 + 80) {
                                myBool = false
                            }
                        }
                        if (playernextX + playerWidth - playerWidth / 3 > x * 80 && playernextX - playerWidth / 3 + playerWidth < x * 80 + 80) {
                            if (playernextY + playerHeight - 1 >= y * 80 && playernextY + playerHeight <= y * 80 + 80) {
                                myBool = false
                            }
                        }
                        if (playernextX + playerWidth / 3 > x * 80 && playernextX + playerWidth / 3 < x * 80 + 80) {
                            if (playernextY + playerHeight / 2 >= y * 80 && playernextY + playerHeight / 2 <= y * 80 + 80) {
                                myBool = false
                            }
                        }
                        if (playernextX + playerWidth - playerWidth / 3 > x * 80 && playernextX - playerWidth / 3 + playerWidth < x * 80 + 80) {
                            if (playernextY + playerHeight / 2 >= y * 80 && playernextY + playerHeight / 2 <= y * 80 + 80) {
                                myBool = false
                            }
                        }
                    }
                }
            }
            return myBool;
        }

        function drawPlayer() {
            ctx.beginPath();
            ctx.drawImage(img, playerX, playerY, playerWidth, playerHeight);
            ctx.closePath();
        }

        function drawObstacle() {
            ctx.beginPath();
            ctx.fillStyle = "black";
            for (var y in obstacle) {
                for (var x in obstacle[y]) {
                    if (obstacle[y][x]) {
                        ctx.drawImage(obs, x * 80, y * 80, 80, 80);
                        ctx.fill();
                    }
                }
            }
            ctx.closePath();
        }

        function draw() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            drawObstacle();
            drawPlayer();

            if (rightPressed && playerX < canvas.width - playerWidth) {
                if (detectColission(playerX + 1, playerY)) {
                    playerX += 1;
                    if (onGround) {
                        if (timer >= 10) {
                            if (count == 5) {
                                count = 0
                            } else {
                                count += 1
                            }
                            timer = 0
                        } else {
                            timer += 1
                        }
                        img.src = tabImgRight[count]
                    }


                } else {
                    img.src = rest
                }
            } else if (leftPressed && playerX > 0) {
                if (detectColission(playerX - 1, playerY)) {
                    playerX -= 1;
                    if (onGround) {
                        if (timer >= 10) {
                            if (count == 5) {
                                count = 0
                            } else {
                                count += 1
                            }
                            timer = 0
                        } else {
                            timer += 1
                        }
                        img.src = tabImgLeft[count]
                    }
                } else {
                    img.src = rest
                }
            } else {
                if (onGround) {
                    timer = 0
                    count = 0
                    img.src = rest
                }
            }

            if (upPressed && playerY > 0) {
                if (detectColission(playerX, playerY)) {
                    if (timer == 5) {
                        if (count != 5) {
                            count += 1
                        }
                        timer = 0
                    } else {
                        timer += 1
                    }
                    if (count == 5) {
                        onGround = false;
                        playerY -= 3;
                    }
                    onGround = false;
                    if (lastKey == "left") {
                        img.src = tabJumpLeft[count]
                    } else {
                        img.src = tabJumpRight[count]
                    }

                } else {
                    upPressed = false;
                }
                if (playerY <= destinationHeight) {
                    upPressed = false;
                }
            }

            if (!upPressed && playerY < (canvas.height - playerHeight)) {
                if (detectColission(playerX, playerY + 2)) {
                    if (!onGround) {
                        if (!detectColission(playerX, playerY + 2) || (playerY >= (canvas.height - playerHeight - 20))) {
                            if (lastKey == "left") {
                                img.src = tabJumpLeft[7]
                            } else {
                                img.src = tabJumpRight[7]
                            }
                        } else {
                            if (lastKey == "left") {
                                img.src = tabJumpLeft[6]
                            } else {
                                img.src = tabJumpRight[6]
                            }
                        }
                    }
                    timer = 0
                    onGround = false;
                    playerY += 2;
                } else {
                    onGround = true;
                    if (timer == 5) {
                        img.src = rest
                        count = 0
                        timer = 0
                        playerY -= 1
                    } else {
                        timer += 1
                    }

                }


            }



            if ((!(playerY < (canvas.height - playerHeight))) && !upPressed && !onGround) {
                if (timer == 5) {
                    img.src = rest
                    onGround = true;
                    count = 0
                    timer = 0
                    playerY -= 1
                } else {
                    timer += 1
                }
            }
        }

        setInterval(draw, 10);
        var self = this
        setInterval(function() {
            self.$emit('getCoords', playerX);
        }, 200)
    },
    methods: {

    }
}
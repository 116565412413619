<template>
  <div class="top">
    <h2>Centres d'intérêt</h2>
  </div>
  <div class="menu">
    <div class="musique " v-on:click="menuActive(0)" v-bind:class="{ active: isActive[0] }"><p><i class="bi bi-music-note-beamed"></i>Musique</p></div>
    <div class="jeux " v-on:click="menuActive(1)" v-bind:class="{ active: isActive[1] }"><p><i class="bi bi-controller"></i>Jeu</p></div>
    <div class="video " v-on:click="menuActive(2)" v-bind:class="{ active: isActive[2] }"><p><i class="bi bi-tv"></i>Vidéo</p></div>
  </div>
  <div>

    <div class="content" v-if="isActive[0]">
      <img src="../assets/image/music/linkinpark.jpg" alt="linkinpark">
      <img src="../assets/image/music/atc.jpg" alt="atc">
      <div><p><i class="bi bi-music-note-beamed"></i>Musique</p></div>
      <img src="../assets/image/music/eminem.jpg" alt="eminem">
      <img src="../assets/image/music/hollywoodundead.jpg" alt="hollywoodundead">
      <img src="../assets/image/music/hoobastank.jpg" alt="hoobastank">
      <img src="../assets/image/music/imaginedragon.jpg" alt="imaginedragon">
      <img src="../assets/image/music/orelsan.jpg" alt="orelsan">
      <img src="../assets/image/music/skillet.jpg" alt="skillet">
      <img src="../assets/image/music/soad.jpg" alt="systemofadown">
    </div>


    <div class="content" v-if="isActive[1]">
      <img src="../assets/image/games/csgo.jpg" alt="csgo">
      <img src="../assets/image/games/lol.jpg" alt="lol">
      <div><p><i class="bi bi-controller"></i>Jeu</p></div>
      <img src="../assets/image/games/minecraft.jpg" alt="minecraft">
      <img src="../assets/image/games/rocketleague.jpg" alt="rocketleague">
      <img src="../assets/image/games/rust.jpg" alt="rust">
      <img src="../assets/image/games/terraria.jpg" alt="terraria">
      <img src="../assets/image/games/valorant.jpg" alt="valorant">
      <img src="../assets/image/games/wow.jpg" alt="wow">
      <img src="../assets/image/games/borderlands.jpg" alt="">
    </div>

    <div class="content" v-if="isActive[2]">
      <img src="../assets/image/movie/hxh.jpg" alt="hunterxhunter">
      <img src="../assets/image/movie/gto.jpg" alt="gto">
      <div><p><i class="bi bi-tv"></i>Vidéo</p></div>
      <img src="../assets/image/movie/reborn.jpg" alt="reborn">
      <img src="../assets/image/movie/steinsgate.jpg" alt="steinsgate">
      <img src="../assets/image/movie/seigneuranneaux.jpg" alt="seigneuranneaux">
      <img src="../assets/image/movie/stalk.jpg" alt="stalk">
      <img src="../assets/image/movie/arcane.jpg" alt="arcane">
      <img src="../assets/image/movie/mrrobot.jpg" alt="mrrobot">
      <img src="../assets/image/movie/fastfurious.jpg" alt="fastfurious">
    </div>
  </div>

</template>

<script>
export default {
  name: 'Loisirs',
  data(){
    return {
      isActive: [true,false,false]
    }
  },
  methods: {
    menuActive: function(number) {
      let temp = !this.isActive[number]
      for (let i = 0; i < 3; i++) {
        this.isActive[i] = false
      }
      this.isActive[number] = temp
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.menu{
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  border: 1px solid #CECECE;
  position: relative;
  width: 60%;
  height: auto;
  margin: 2% auto;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 5px 5px 11px 5px rgba(0,0,0,0.49);
}
.content>:nth-child(1n){
  flex: 1 0 17%;
  width: 15%;
}
.content>div{
    background: #cecece;
    color:#333333;
    font-weight:600;
    display: table; 
}

.content>div>p{
  vertical-align: middle;
}

.menu>div{
  margin: 1%;
  border: 3px solid #cecece;
  border-radius: 5%;
  width: 150px;
  height: 150px;
  display: table; 
  font-weight:600;

  background: linear-gradient(to right, #cecece 50%, #333333 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;

}
.menu>div:hover{
    cursor: pointer;
    background-position: left bottom;
}
.menu>div:hover p{
    color:#333333;
}
p{
  font-size: 150%;
  text-align:center; 
  vertical-align: bottom;
  display: table-cell;
  padding: 10%;
}
i{
  display: block;
  font-size: 250%;
}
*{
  font-family: 'PT Sans', sans-serif;
}
.active{
    border-color: #333333;
    background: #cecece !important;
}
.active>p{
  color:#333333;
}

@media (max-width: 1000px) {
  .content>div>p{
    font-size: 100%;
  }
}

@media (max-width: 800px) {
  .content>div{
    display:none;
  }
  .content>:nth-child(1n){
    flex: 1 0 26%;
    width: 15%;
  }
}

@media (max-width: 600px) {
  .menu>div{
    width:100px;
    height:100px;
  }
  p{
    font-size: 100%;
  }
  h2{
  font-size: 36px;
}
}
</style>

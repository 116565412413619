<section class="header">
    <div class="dropmenu" v-show="show">
        <div class="closeMenu" @click="show=false">
            <div class="light top"></div>
            <div class="light bottom"></div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.85 468.63" class="logoMenu"><defs>
        </defs>
        <g id="Calque_2" data-name="Calque 2">
        <g id="Calque_1-2" data-name="Calque 1">
        <path class="cls-1 logoContour" d="M202.92,47.36l161.91,93.48v187L202.92,421.27,41,327.79v-187l161.9-93.48m0-47.36L0,117.16V351.47L202.92,468.63,405.85,351.47V117.16L202.92,0Z"/>
        <polygon class="cls-1 logoLetter" points="191.05 339.73 191.05 254.68 191.05 242.81 191.05 213.67 128.45 213.67 128.45 254.68 150.03 254.68 150.03 316.05 105.69 290.45 105.69 178.18 191.05 128.9 191.05 81.54 64.68 154.5 64.68 314.13 191.05 387.1 191.05 339.73"/>
        <polygon class="cls-1 logoLetter" points="214.8 81.59 214.8 128.95 300.07 178.18 300.07 290.45 214.8 339.68 214.8 387.05 341.08 314.13 341.08 154.5 214.8 81.59"/>
        </g></g></svg>
        <div class="hexa3">
            <div class="hexagon topleft">
                <router-link to="/propos" class="hexagon2" @click="show=false">
                    <a class="hexatext">À propos</a>
                </router-link>
            </div>
        </div>

        <div class="hexa3">
            <div class="hexagon topright2">
                <router-link to="/Contact" class="hexagon2" @click="show=false">
                    <a class="hexatext">Contact</a>
                </router-link>
            </div>
        </div>

        <div class="hexa3">
            <div class="hexagon middleleft">
                <router-link to="/parcours" class="hexagon2" @click="show=false">
                    <a class="hexatext">Parcours</a>
                </router-link>
            </div>
        </div>

        <div class="hexa3">
            <div class="hexagon middleright">
                <router-link to="/loisirs" class="hexagon2" @click="show=false">
                    <a class="hexatext">Centres<br>d'intérêt</a>
                </router-link>
            </div>
        </div>

        <div class="hexa3">
            <div class="hexagon bottomleft">
                <router-link to="/competences" class="hexagon2" @click="show=false">
                    <a class="hexatext">Compétences</a>
                </router-link>
            </div>
        </div>

        <div class="hexa3">
            <div class="hexagon bottomright">
                <router-link to="/" class="hexagon2" @click="show=false">
                    <a class="hexatext">À venir</a>
                </router-link>
            </div>
        </div>

    </div>
    <div class="frame">
        <router-link to="/">
            <img src="../../assets/image/logo/GD_grey.svg" class="logo filter-cece" alt="Logo">
        </router-link>
        <div class="menu" @click="show=true" v-show="!show">
            <div class="light top"></div>
            <div class="light middle"></div>
            <div class="light bottom"></div>
        </div>

    </div>

    <div class="modal" id="modal">

        <div class="modal-content">
            <span class="close">&times;</span>
            <button class="mention active" @click="changeActive('mention')">Mentions légales</button>
            <button class="cgu" @click="changeActive('cgu')">Conditions générales d'utilisations</button>
            <hr>
            <div class="modalContentCGU" v-show="cgu">
                <p>Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par Delfly Guillaume et de définir les conditions d’accès et d’utilisation
                    des services par « l'Utilisateur ». Les présentes CGU sont accessibles sur le site à la rubrique «CGU».</p>
                <hr>
                <h3>Article 1 - ACCÈS AU SITE</h3>
                <p>Le site https://guillaume-delfly.fr/ permet à l'Utilisateur un accès gratuit à la présentation de l'activité de son créateur.</p>
                <p>Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.</p>
                <hr>

                <h3>Article 2 - COLLECTE DES DONNÉES</h3>
                <p>Le site est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où il ne collecte aucune donnée concernant les Utilisateurs.</p>
                <hr>

                <h3>Article 3 - PROPRIÉTÉ INTELLECTUELLE</h3>
                <p>Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.
                </p>
                <p>L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à
                    des fins commerciales et publicitaires est strictement interdite.</p>
                <p>Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la
                    propriété intellectuelle</p>
                <p>Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.
                </p>
                <hr>
                <h3>Article 4 - RESPONSABILITÉ</h3>
                <p>Les sources des informations diffusées sur le site https://guillaume-delfly.fr/ sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.</p>
                <p>Malgré des mises à jour régulières, le site https://guillaume-delfly.fr/ ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue
                    responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.</p>
                <p>Le site https://guillaume-delfly.fr/ ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant
                    de ce site.</p>
                <p>La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.</p>
                <hr>
                <h3>Article 5 - LIENS HYPERTEXTES</h3>
                <p>Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site https://guillaume-delfly.fr/. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent
                    ces liens et ne saurait, en aucun cas, être responsable de leur contenu.</p>
                <hr>
                <h3>Article 6 - DROIT APPLICABLE ET JURIDICTION COMPÉTENTE</h3>
                <p>La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître. Pour toute question relative à l’application
                    des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites dans les mentions légales.
                </p>
            </div>
            <div class="modalContentMention" v-show="mention">
                <p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs, ci-après l""<strong>Utilisateur</strong>",
                    du site https://www.guillaume-delfly.fr/ , ci-après le "<strong>Site</strong>", les présentes mentions légales.</p>
                <p>La connexion et la navigation sur le Site par l’Utilisateur implique acceptation intégrale et sans réserve des présentes mentions légales.</p>
                <p>Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».</p>
                <hr>

                <h3>Article 1 - L'EDITEUR</h3>
                <p>L’édition et la direction de la publication du Site est assurée par Delfly Guillaume, domiciliée 12 place des aulnes, 02000 Aulnois-sous-laon, dont le numéro de téléphone est 0767362244, et l'adresse email delfly.guillaume@gmail.com. ci-après
                    l'"
                    <strong>Editeur</strong>".</p>
                <hr>

                <h3>Article 2 - L'HEBERGEUR</h3>
                <p>L'hébergeur du Site est la société OVH, dont le siège social est situé au 2 rue Kellermann - 59100 Roubaix - France, avec le numéro de téléphone : 09 72 10 10 07</p>
                <hr>

                <h3>Article 3 - ACCES AU SITE</h3>
                <p>Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découlant d’une nécessité de maintenance.</p>
                <p>En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.</p>
                <hr>

                <h3>Article 4 - COLLECTE DES DONNEES</h3>
                <p>Le site est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où il ne collecte aucune donnée concernant les utilisateurs.
                </p>
                <p>Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site, sans autorisation de l’Editeur est prohibée et pourra entraînée des actions et poursuites judiciaires telles que notamment prévues
                    par le Code de la propriété intellectuelle et le Code civil.</p>
            </div>
        </div>
    </div>

    <div class="footer">
        <p>© 2022 - DELFLY Guillaume</p>
        <img src="../../assets/image/logo/GD_grey.svg" alt="Logo">
        <p id="modalButton">CGU et Mentions légales</p>
    </div>

</section>